<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>

        <h2 class="text--primary">
          People Count Web Service
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form
                    ref="loginForm"
                    @submit.prevent="handleFormSubmit"
                  >
                    <v-text-field
                      v-model="idText"
                      outlined
                      label="ユーザID"
                      placeholder="ユーザID"
                      :error-messages="errorMessages.id"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>

                    <v-text-field
                      v-model="passwordText"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="パスワード"
                      :error-messages="errorMessages.password"
                      placeholder="パスワード"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-2"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <v-btn
                      block
                      color="primary"
                      type="submit"
                      class="mt-6"
                      style="text-transform: none"
                    >
                      ログイン
                    </v-btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col>
          <!-- <label>Display items on Dashboard Page</label>
          <div
            v-for="option in dashboardOptions"
            :key="option.value"
          >
            <v-checkbox
              v-model="selectedValues"
              :value="option.value"
              :label="option.title"
              hide-details
            />
          </div> -->
            </v-col>
            <v-col
              v-if="accessLimit"
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto mb-3"
            >
              <img
                style="width:400px;"
                src="@/assets/images/misc/notService.png"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, getCurrentInstance } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import axios from '@axios'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'
import { constant } from '@core/utils/constant'
import crypto from 'crypto-js'

export default {
  setup() {
    // Template Ref
    const { router } = useRouter()
    const loginForm = ref(null)
    const vm = getCurrentInstance().proxy
    const isPasswordVisible = ref(false)
    const idText = ref('')

    // const password = ref('lcsczxcasd')
    const passwordText = ref('')
    const errorMessages = ref([])
    const accessLimit = ref(false)

    // clear sessionStorage
    let n = sessionStorage.length
    while (n > 0) {
      n -= 1
      const key = sessionStorage.key(n)
      sessionStorage.removeItem(key)
    }

    const selectedValues = ref([])
    const dashboardDisplayItems = localStorage.getItem('dashboardDisplayItems')
    console.log(dashboardDisplayItems)
    if (dashboardDisplayItems === null) {
      selectedValues.value = [1, 2, 3, 4, 5]
    } else {
      selectedValues.value = JSON.parse(dashboardDisplayItems)
    }

    const handleFormSubmit = () => {
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return

      /*
        1. Make HTTP request to get accessToken
        2. Store received token in localStorage for future use
        3. Make another HTTP request for getting user information
        4. On successful response of user information redirect to home page

        ? We have use promise chaining to get user data from access token
        ? Promise Chaining: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises#chaining
      */
      const userId = idText.value
      const password = passwordText.value
      axios
        .post('/welcome!login.sv', null, {
          params: {
            userId,
            password,
          },
        })
        .then(response => {
          accessLimit.value = false
          if (response.data.result.data === null) {
            if (response.data.result.message === 'accessLimit') {
              accessLimit.value = true

              return
            }
            const error = {
              id: [response.data.result.message],
            }
            errorMessages.value = error
          } else {
            const { user } = response.data.result.data

            user.avatar = require('@/assets/images/avatars/1.png')

            const userAbility = JSON.parse(user.ability)
            const { siteList } = response.data.result.data

            const { brandInfoList } = response.data.result.data

            const { storeInfoList } = response.data.result.data

            // Set user ability
            // ? https://casl.js.org/v5/en/guide/intro#update-rules
            vm.$ability.update(userAbility)

            // We will store `userAbility` in sessionStorage separate from userData
            // Hence, we are just removing it from user object
            delete user.ability

            // 暗号化する
            const abilityEcrypted = crypto.AES.encrypt(JSON.stringify(userAbility), constant.secret)
            sessionStorage.setItem('userAbility', abilityEcrypted.toString())

            const siteListEcrypted = crypto.AES.encrypt(JSON.stringify(siteList), constant.secret)
            sessionStorage.setItem('siteList', siteListEcrypted.toString())

            const brandInfoListEcrypted = crypto.AES.encrypt(JSON.stringify(brandInfoList), constant.secret)
            sessionStorage.setItem('brandInfoList', brandInfoListEcrypted.toString())

            const ecrypted = crypto.AES.encrypt(JSON.stringify(user), constant.secret)
            sessionStorage.setItem('data', ecrypted.toString())

            const { accessToken } = response.data.result.data

            sessionStorage.setItem('accessToken', accessToken)

            if (user.domesticFlag === 1) {
              const currentBrand = brandInfoList[0]

              sessionStorage.setItem('currentBrandId', currentBrand.brandId)
              sessionStorage.setItem('currentBrandName', currentBrand.brandName)

              sessionStorage.setItem('currentStoreId', storeInfoList[0].siteId)
              sessionStorage.setItem('currentStoreName', storeInfoList[0].siteName)
              sessionStorage.setItem('storeInfoList', JSON.stringify(storeInfoList))
            }

            localStorage.setItem('dashboardDisplayItems', JSON.stringify(selectedValues.value))

            // On success redirect to home
            router.push('/')
          }
        })
        .catch(error => {
          // TODO: Next Update - Show notification
          // console.error('Oops, Unable to login!')
          // console.log('error :>> ', error.response)
          // errorMessages.value = error.response.data.error

          console.log(error)
          accessLimit.value = false
        })
    }

    // const dashboardOptions = [
    //   { title: 'People & Last Year', value: 1 },
    //   { title: 'People, Car, Bike', value: 2 },
    //   { title: 'Staying Visitor', value: 3 },
    //   { title: 'SC Comparison', value: 4 },
    //   { title: 'Weather Information', value: 5 },
    // ]

    return {
      selectedValues,

      // dashboardOptions,
      handleFormSubmit,
      isPasswordVisible,
      idText,
      passwordText,
      errorMessages,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      loginForm,
      accessLimit,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
<style scoped>
.v-input {
  font-size: 20px;
}
</style>
